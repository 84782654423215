import { useEffect } from 'react';
import { useState } from 'react';
import { Cookies } from 'react-cookie';
const cookies = new Cookies();
const virtualPath = '';


const config = {
    secret_key: process.env.REACT_APP_SECRET_KEY,
}
export default config

let baseUrl;
if (window.location.hostname.indexOf("react.") >= 0) {
    baseUrl = `${window.location.protocol}//${window.location.host}/${window.location.pathname.split('/')[1]}`;
} else {
    baseUrl = `${window.location.protocol}//${window.location.host}`;
}
export const API_SERVER = baseUrl;

export const ApiCall = (url, bodyData) => {

    let payloadData = {
        ...bodyData,
        Token: "LXGIWYLHESOYAMAEZAKMI",
        LoginUserCode: localStorage.getItem('loginUserCode') ? localStorage.getItem('loginUserCode') : '',
      //  LoginToken: localStorage.getItem('loginToken') ? localStorage.getItem('loginToken') : ''

    }
    const abortController = new AbortController();
    let data = fetch(`${API_SERVER + url}`, {
        method: "POST",
        body: JSON.stringify(payloadData),
        headers: {
            "Content-Type": "application/json"
        },
        signal: abortController.signal
    }).then((res) => res.json())
        .then((res) => {

            return res;
        })
        .catch((err) => {
            return err
        }).finally(() => {
            abortController.abort();
        });
    return data
};

export const AdminApiCall = (url, bodyData) => {

    let payloadData = {
        ...bodyData,
        LoginToken: getCookie('adminLoginToken'),
        LoginUserCode: getCookie('adminLoginUserCode'),
        Source : "Admin",
        Token: "LXGIWYLHESOYAMAEZAKMI"
    }
    const abortController = new AbortController();
    let data = fetch(`${API_SERVER + url}`, {
        method: "POST",
        body: JSON.stringify(payloadData),
        headers: {
            "Content-Type": "application/json"
        },
        signal: abortController.signal
    }).then((res) => res.json())
        .then((res) => {

            return res;
        })
        .catch((err) => {
            return err
        }).finally(() => {
            abortController.abort();
        });
    return data
};


export const FetchAutoCompleteData = async (Data, preFix, type, contextKey = '', contextKey2 = '', contextKey3 = '', contextKey4 = '') => {
    let { ...rest } = Data;
    let _Data = {
        ...rest,
        LoginToken: getCookie('adminLoginToken'),
        LoginUserCode: getCookie('adminLoginUserCode'),
        Source: "Admin",
        Token: "LXGIWYLHESOYAMAEZAKMI"
    }
    let dataList = [];
    try {
        if (preFix !== undefined && preFix !== '') {
            const abortController = new AbortController();
            const response = await fetch(`${API_SERVER}/api/AutoComplete/AutoComplete`, {
                method: 'POST',
                body: JSON.stringify({ ..._Data, Type: type, preFix, ContextKey: contextKey, ContextKey2: contextKey2, ContextKey3: contextKey3, ContextKey4: contextKey4 }),
                headers: {
                    "Content-Type": "application/json"
                },
                signal: abortController.signal
            });

            const res = await response.json();

            let _datalist = res.autoCompleteData;
            for (let i = 0; i < _datalist?.length; i++) {
                let _value = (_datalist[i]?.values);
                dataList?.push(_value);
            }
            return dataList;
        }
        else {
            return [];
        }
    } catch (error) {
        console.error('Error fetching suggestions:', error);
        return [];
    }
};


export const setCookie = (name, value, days) => {
    let expires = "";
    if (days) {
        const date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "") + expires + "; path=/";
};

export const getCookie = (name) => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
};

export const deleteCookie = (name) => {
    setCookie(name, "", -1);
};


export const SetSession = (type, key, value) => {
    let tempKey = (virtualPath).replace("/", "").toLowerCase() + "_" + key;
    if (type.toLowerCase() === ('localStorage').toLowerCase()) {
        window.localStorage.setItem(tempKey, value);
    } else if (type.toLowerCase() === ('cookies').toLowerCase()) {
        cookies.set(tempKey, value, { path: "/" });
    } else if (type.toLowerCase() === ('sessionStorage').toLowerCase()) {
        sessionStorage.setItem(tempKey, value);
    }
}

export const GetSession = (type, key) => {
    let tempKey = (virtualPath).replace("/", "").toLowerCase() + "_" + key;
    if (type.toLowerCase() === ('localStorage').toLowerCase()) {
        let data = window.localStorage.getItem(tempKey);
        return data
    } else if (type.toLowerCase() === ('cookies').toLowerCase()) {
        let data = cookies.get(tempKey);
        return data
    } else if (type.toLowerCase() === ('sessionStorage').toLowerCase()) {
        let data = sessionStorage.getItem(tempKey);
        return data
    }
}

export const CommonApiData = () => {
    var user = GetSession('cookies', 'user');
    if (user) {
        let data = {
            Token: user.token ? user.token : '',
            LoginUserCode: user.loginUserCode ? user.loginUserCode : '',
            User: user.userName ? user.userName : '',
            loginBCode: user.loginBCode ? user.loginBCode : '',
            Source: 'Web',
        }
        return data
    } else {
        return {
            Token: 'EXPIRED',
            LoginUserCode: '',
            User: '',
            Source: 'Web',
        };
    }
}


export function formatDate(date) {
    const monthNames = [
        "Jan", "Feb", "Mar", "Apr", "May", "Jun",
        "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
    ];

    var day = ('0' + date.getDate()).slice(-2);
    var month = monthNames[date.getMonth()];
    var year = date.getFullYear();

    return day + '-' + month + '-' + year;
}

export const DropDownData = (Type, ContextKey = '', ContextKey2 = '', ContextKey3 = '', ContextKey4 = '') => {
    const [data, setData] = useState({});
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState({});
    let Data = {
        LoginToken: getCookie('adminLoginToken'),
        LoginUserCode: getCookie('adminLoginUserCode'),
        Source: "Admin",
        PageIndex : "1" ,
        Token: "LXGIWYLHESOYAMAEZAKMI"
    };
    useEffect(() => {
        const abortController = new AbortController();
        fetch(`${API_SERVER}/api/AutoComplete/GetMasterData`, {
            method: 'POST',
            body: JSON.stringify({ ...Data, Type, ContextKey, ContextKey2, ContextKey3, ContextKey4 }),
            headers: {
                "Content-Type": "application/json"
            },
            signal: abortController.signal
        }).then((res) => res.json())
            .then((res) => {
                setData(res)
                setLoading(false)
            })
            .catch((err) => {
                setError(err)
                setLoading(false)
            })
            .finally(() => {
                abortController.abort();
            });
    }, [Type, ContextKey, ContextKey2])
    return { data, error, loading };
};