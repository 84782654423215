import React from "react";

const Loader = () => {
    return (
        <>
            <div className="loader">
                <div className="loader-inner">
                    <div className="wait">Enjoy reliable and affordable internet in your trips</div>
                    <div className="iata_code departure_city">Sim</div>
                    <div className="plane">
                        <img
                            src="https://zupimages.net/up/19/34/4820.gif"
                            className="plane-img"
                            alt="Loading"
                        />
                    </div>
                    <div className="earth-wrapper">
                        <div className="earth"></div>
                    </div>
                    <div className="iata_code arrival_city">Fly</div>
                </div>
            </div>
        </>
    );
};
export default Loader;

