import { useEffect, useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { ApiCall } from '../../EnvConfig/env_config';
import { toast } from 'react-toastify';
import { setFocus } from '../Common/Common';


const ResetPasswordField = () => {
    const [resetPassdata, setResetPassdata] = useState({
        newPassword : "",
        confirmPassword : "",
        userCode : "",
        authToken : "",
    })
    const navigate = useNavigate()
    const location = useLocation();

    const handleResetPassword = async (e) => {
        e.preventDefault()

        try {
            const response = await ApiCall(`/api/Web/UpdateForgotPassword`, resetPassdata);
            if (response.status === 'SUCCESS') {
                toast.success(response.message)
                navigate("/login")
            } else if (response.status === 'ERROR') {
                toast.error(response.message)
            }
        } catch (error) {
            toast.error('There was a problem with the server, Please try again later');
        }
    }

    useEffect(() => {
        setFocus('txtNewPassword')
        const searchParams = new URLSearchParams(location.search);
        const userCode = searchParams.get('UserCode');
        const AuthToken = searchParams.get('AuthToken');
        setResetPassdata({
            ...resetPassdata,
            authToken: AuthToken,
            userCode: userCode,
        })
    }, [location.search]);

    return (
        <>
            <div className="login_wrapper">
                <div className="container">
                    <div className="row justify-content-center align-items-center">
                        <div className="col-md-6 col-lg-5 col-xl-4">
                            <div className="text-center mb-3 mb-md-5">
                                <a className="navbar-brand fw-bold fs-1 primary-text" href="/">Simfly</a>
                            </div>

                            <div className="card br-12">
                                <div className="card-body">
                                    <h5 className='text-center mb-3'>Reset Your Password</h5>
                                    <form>
                                        <div className="form-group mb-3">

                                            <input
                                                type="password"
                                                className="form-control"
                                                id='txtNewPassword'
                                                name='newPassword'
                                                value={resetPassdata.newPassword}
                                                placeholder="Enter New Password"
                                                onChange={(e) => setResetPassdata({ ...resetPassdata, newPassword: e.target.value })}
                                                onKeyDown={(e) => {
                                                    if (e.key === 'Enter') {
                                                        e.preventDefault()
                                                        setFocus('txtConfirmPassword')
                                                    };
                                                }}
                                            />
                                        </div>
                                        <div className="form-group mb-3">

                                            <input
                                                type="password"
                                                className="form-control"
                                                id='txtConfirmPassword'
                                                name='confirmPassword'
                                                value={resetPassdata.confirmPassword}
                                                placeholder="Enter confirm password"
                                                onChange={(e) => setResetPassdata({ ...resetPassdata, confirmPassword: e.target.value })}
                                                onKeyDown={(e) => {
                                                    if (e.key === 'Enter') {
                                                        handleResetPassword(e)
                                                    };
                                                }}
                                            />
                                        </div>
                                        <button onClick={(e) => handleResetPassword(e)} className="mybtn primary-bg text-white w-100">
                                            Reset Your Password
                                        </button>
                                        <div className="mt-3 text-center">
                                            <Link className='small' to="/login">Login</Link>
                                        </div>
                                        <div className="mt-3 text-center">
                                            <span
                                                className='pointer'
                                                onClick={async () => {
                                                    await localStorage.setItem("signAsGuest", true)
                                                    navigate("/")
                                                }}
                                            ><i className="bi bi-person-badge me-2"></i>Signin as a guest</span>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ResetPasswordField;