import { configureStore } from '@reduxjs/toolkit'
import  userSlice  from '../features/authSlice'
import  addToCart  from '../features/addToCartSlice'
import commonData from '../features/commonSlice'



export const store = configureStore({
    reducer: {
        authData: userSlice,
        addToCartDetails: addToCart,
        commonData: commonData
    }
})