
import { useEffect, useState } from "react";
import { AdminApiCall } from "../../../EnvConfig/env_config";
import { handleDateChange, setFocus } from "../../Common/Common";
import { AlertBox, DateBox, InputBox, SelectInputBox } from "../../Common/Input";
import Loader from "../../Common/Loader";
import { NewTable } from "../../Common/NewTable";

const UserMasterNew = ({ open, setOpen }) => {
    const [edit, setEdit] = useState(false)
    const [showform, setShowForm] = useState(false)
    const [isLoading, setIsLoading] = useState(false);
    const [sortConfig, setSortConfig] = useState({ SortColumn: null, SortOrder: null });
    const [currentPageNo, setCurrentPageNo] = useState(1);
    const [pageSize, setPageSize] = useState("10");
    const [tableData, settableData] = useState([]);
    const [filterData, setFilterData] = useState({
        type: "S",
        searchText: "",
        searchFieldValue: "",
        startValue: "",
        AdvanceFilter: []
    })
    const [groupNameDropdown, setGroupNameDropdown] = useState([]);
    const [formData, setFormData] = useState({
        userCode: 'System Generated',
        userName: '',
        password: '',
        dateofJoining: '',
        address: '',
        groupName: '',
        mobileNo: '',
        emailID: '',
        pinCode: '',
        city: '',
        active: true,
    });

    const handleClear = () => {
        setFormData({
            userCode: 'System Generated',
            userName: '',
            password: '',
            dateofJoining: '',
            address: '',
            groupName: '',
            mobileNo: '',
            emailID: '',
            pinCode: '',
            city: '',
            active: true,
        });
        filterData.SearchText = ''
        getUserMasterData()
        setEdit(false)
    };

    const handleSubmit = async () => {
        let request = {
            ...formData,
            Source: "Admin"
        }
        try {
            setIsLoading(true)
            const response = await AdminApiCall(`/api/User/InsertUpdateUserMaster`, request);
            if (response.status === 'SUCCESS') {
                AlertBox('SUCCESS', response.message, response.focus)
                handleClear();
                getUserMasterData();
            } else if (response.status === 'ERROR') {
                setFocus(response.focus);
                AlertBox('ERROR', response.message)
            }
        } catch (error) {
            AlertBox('ERROR', 'There was a problem with the server, Please try again later')
        }
        finally {
            setIsLoading(false)
        }
    };

    const handleDelete = async (id) => {
        const request = {
            userID: `${id}`,
        }
        try {
            setIsLoading(true)
            const response = await AdminApiCall('/api/User/DeleteUserMaster', { ...request })
            if (response.status === 'SUCCESS') {
                handleClear()
                getUserMasterData();
                setFocus(response.focus);
                AlertBox('SUCCESS', response.message)
            } else if (response.status === 'EXPIRED') {
                AlertBox('ERROR', response.message)
            }
            else if (response.status === 'ERROR') {
                AlertBox(response.status, response.message, response.focus)
            }
            else if (response.status === 'UNAUTHORIZED') {
                AlertBox(response.status, response.message, response.focus)
            }
        }
        catch (error) {
            AlertBox('ERROR', 'Failed to show the data. Please try again.')
        }
        finally {
            setIsLoading(false)
        }
    }

    const handleSorting = (SortColumn) => {
        let SortOrder = 'ASC';

        if (sortConfig && sortConfig.SortColumn === SortColumn && sortConfig.SortOrder === 'ASC') {
            SortOrder = 'DESC';
        }
        setSortConfig({ SortColumn, SortOrder });
    };

    const handleEdit = async (id) => {
        const request = {
            userID: `${id}`,
        }
        try {
            setIsLoading(true)
            const response = await AdminApiCall('/api/User/EditUserMaster', { ...request })
            if (response.status === 'SUCCESS') {
                setShowForm(true)
                setEdit(true)
                setFocus(response.focus);
                const { productPreviewUrl, focus, message, status, ...restResponse } = response;
                setFormData(restResponse);
            } else if (response.status === 'EXPIRED') {
                AlertBox('ERROR', response.message)
            }
            else if (response.status === 'ERROR') {
                AlertBox(response.status, response.message, response.focus)
            }
            else if (response.status === 'UNAUTHORIZED') {
                AlertBox(response.status, response.message, response.focus)
            }
        }
        catch (error) {
            AlertBox('ERROR', 'Failed to show the data. Please try again.')
        }
        finally {
            setIsLoading(false)
        }
    }

    const getUserMasterData = async () => {
        let request = {
            ...filterData,
            ...sortConfig,
            pageIndex: `${currentPageNo}`
        }
        try {
            setIsLoading(true)
            let res = await AdminApiCall('/api/User/ShowUserMaster', { ...request, pageSize: pageSize })
            if (res.Status === 'SUCCESS') {
                settableData(res);
            } else if (res.Status === 'EXPIRED') {
                AlertBox('ERROR', res.message)
            }
            else if (res.Status === 'ERROR') {
                settableData([])
            }
        }
        catch (error) {
            AlertBox('ERROR', 'Failed to show the data. Please try again.')
        }
        finally {
            setIsLoading(false)
        }
    }

    const userGroupDropdownList = async () => {
        let request = {
            Condition: '',
            TextPart: 'UserGroupName',
            ValuePart: 'UserGroupID',
            TableName: 'UserGroupMaster',
        }
        try {
            setIsLoading(true)
            let res = await AdminApiCall('/api/User/BindDropDownList', { ...request })
            if (res.status === 'SUCCESS') {
                setGroupNameDropdown(res.dropDownList);
            } else if (res.status === 'EXPIRED') {
                AlertBox('ERROR', res.message)
            }
            else if (res.status === 'ERROR') {
                setGroupNameDropdown([])
            }
        }
        catch (error) {
            AlertBox('ERROR', 'Failed to show the data. Please try again.')
        }
        finally {
            setIsLoading(false)
        }
    }

    const handleChange1 = (event, pagenumber) => {
        setCurrentPageNo(pagenumber)
    }
    const showFormHandle = () => {
        setShowForm(!showform)
        if (showform) {
            handleClear()
        }
        setFocus("txtUserCode")
    }


    useEffect(() => {
        document.title = 'User Master'
        setFocus("txtUserCode")
        getUserMasterData()
    }, [currentPageNo, sortConfig,])

    useEffect(() => {
        userGroupDropdownList()
    }, [])


    return (
        <div style={{ marginTop: "64px" }}>
            <main id="main" className={`main ${open ? '' : 'margin_left'}`}  >
                <section className="p-2 mt-2">
                    <div className='headerbg p-3  d-flex justify-content-between'>
                        <h4 className='primary-text'>{'User Master'}</h4>
                        <div> {showform ? (<button className="hideshowbtn hideshowbtninside secondary-bghideshowbtn text-dark me-2" onClick={showFormHandle}>
                            <i className="fa-solid fa-x  me-1" />Close Form
                        </button>) : (<button className="hideshowbtn hideshowbtninside secondary-bghideshowbtn text-dark me-2" onClick={showFormHandle}>
                            <i className="bi bi-check-lg " /> Show Form
                        </button>)}</div>
                    </div>
                    {showform ? <>
                        <div className='admin_classmain-card rounded-2'>
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="row ">

                                        <InputBox
                                            label='User Code'
                                            id='txtUserCode'
                                            placeholder="System Generated"
                                            disabled
                                            required
                                            onKeyDown={(e) => {
                                                if (e.key === 'Enter') {
                                                    setFocus("txtUserName")
                                                };
                                            }}
                                        />

                                        <InputBox
                                            label='User Name'
                                            id='txtUserName'
                                            maxLength='30'

                                            placeholder="User Name"
                                            value={formData.userName}
                                            onChange={(e) => setFormData({ ...formData, userName: e.target.value })}
                                            required
                                            onKeyDown={(e) => {
                                                if (e.key === 'Enter') {
                                                    setFocus("txtPassword")
                                                };
                                            }}
                                        />
                                        <InputBox
                                            type="password"
                                            label='Password'
                                            id='txtPassword'
                                            maxLength='15'
                                            minLength='8'
                                            placeholder="Password"
                                            disabled={edit}
                                            value={formData.password}
                                            onChange={(e) => setFormData({ ...formData, password: e.target.value })}
                                            required
                                            onKeyDown={(e) => {
                                                if (e.key === 'Enter') {
                                                    setFocus("txtGroupName")
                                                };
                                            }}
                                        />

                                        <SelectInputBox
                                            id="txtGroupName"
                                            divclassname="col col-lg-3 fields mt-3"
                                            label="Group Name"
                                            required={true}
                                            value={formData.groupName ? formData.groupName : ""}
                                            onChange={(e) => {
                                                setFormData({ ...formData, groupName: e.target.value })
                                            }}
                                            onKeyDown={(e) => {
                                                if (e.key === 'Enter') {
                                                    setFocus("txtMobileNumber")
                                                }
                                            }}
                                        >
                                            <option>--Choose Group Name--</option>
                                            {
                                                groupNameDropdown?.map((item, index) => {
                                                    return (
                                                        <option key={index} value={item.valuePart}>{item.textPart}</option>
                                                    )
                                                })
                                            }
                                        </SelectInputBox>
                                        <InputBox
                                            onKeyDown={(e) => {
                                                if (e.key === 'Enter') {
                                                    setFocus("txtEmailID")
                                                };
                                            }}
                                            type="tel"
                                            label='Mobile No'
                                            id='txtMobileNumber'
                                            maxLength='10'
                                            placeholder="Mobile No"
                                            value={formData.mobileNo}
                                            onChange={(e) => {
                                                let { value } = e.target
                                                const mobileNo = value.replace(/[^0-9]/g, '')
                                                setFormData({ ...formData, mobileNo: mobileNo })
                                            }}
                                            required
                                        />
                                        <InputBox
                                            onKeyDown={(event) => {
                                                if (event.key === 'Enter') {
                                                    setFocus('txtAddress');
                                                }
                                            }}
                                            label='Email ID '
                                            id='txtEmailID'
                                            maxLength='50'
                                            placeholder="Email ID"
                                            value={formData.emailID}
                                            onChange={(e) => setFormData({ ...formData, emailID: e.target.value })}
                                            required
                                        />
                                        <InputBox
                                            label='Address'
                                            id='txtAddress'
                                            maxLength='60'
                                            placeholder="Address"
                                            value={formData.address}
                                            onChange={(e) => setFormData({ ...formData, address: e.target.value })}
                                            required
                                            onKeyDown={(e) => {
                                                if (e.key === 'Enter') {
                                                    setFocus("txtPinCode")
                                                };
                                            }}
                                        />
                                        <InputBox
                                            label='Pin Code'
                                            id='txtPinCode'
                                            maxLength='6'
                                            placeholder="Pin Code"
                                            value={formData.pinCode}
                                            onChange={(e) => setFormData({ ...formData, pinCode: e.target.value })}
                                            required
                                            onKeyDown={(e) => {
                                                if (e.key === 'Enter') {
                                                    setFocus("txtCity")
                                                };
                                            }}
                                        />

                                        <InputBox
                                            label='City'
                                            id='txtCity'
                                            maxLength='30'
                                            placeholder="City"
                                            value={formData.city}
                                            onChange={(e) => setFormData({ ...formData, city: e.target.value })}
                                            required
                                            onKeyDown={(e) => {
                                                if (e.key === 'Enter') {
                                                    setFocus("txtDateofJoining")
                                                };
                                            }}
                                        />

                                        <DateBox
                                            id='txtDateofJoining'
                                            divclassname="col col-lg-3 fields mt-3"
                                            label='Date of Joining'
                                            placeholder='Date of Joining'
                                            selected={formData.dateofJoining === '' ? '' : new Date(formData.dateofJoining)}
                                            onChange={(e) => handleDateChange(e, setFormData, formData, 'dateofJoining')}
                                            autoComplete="off"
                                            onKeyDown={(e) => {
                                                if (e.key === 'Enter') {
                                                    setFocus('activeCheckbox')
                                                }
                                            }}
                                        />
                                        <div className="col-lg-3 col-md-4 col-sm-6 d-flex  align-items-end">
                                            <div className="form-check form-switch ">
                                                <label
                                                    className="form-check-label"
                                                    htmlFor="activeCheckbox"
                                                >
                                                    Active
                                                </label>
                                                <input
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    id="activeCheckbox"
                                                    name='active'
                                                    checked={formData.active}
                                                    onChange={(e) => setFormData({ ...formData, active: e.target.checked })}
                                                    onKeyDown={(e) => {
                                                        if (e.key === 'Enter') {
                                                            handleSubmit();
                                                        };
                                                    }}
                                                />
                                            </div>
                                        </div>

                                        <div className="col-lg-12 mt-4">
                                            <div className="lms-new-button ">
                                                {edit ? (<button className="admin_mybtn mybtn secondary-bg text-dark me-2" onClick={handleSubmit}>
                                                    <i className="bi bi-check-lg" /> Update
                                                </button>) : (<button className="admin_mybtn mybtn secondary-bg text-dark me-2" onClick={handleSubmit}>
                                                    <i className="bi bi-check-lg " /> Save
                                                </button>)}

                                                <button className="admin_mybtn mybtn secondary-bg text-dark me-2" onClick={handleClear}>
                                                    <i className="bi bi-arrow-clockwise" /> Reset
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </> : <></>}

                </section>

                <NewTable handleEdit={handleEdit}
                    handleDelete={handleDelete}
                    PageName={'UserMaster'}
                    handleFilter={() => {
                        getUserMasterData()
                        setFilterData({
                            ...filterData,
                        })
                    }}
                    filterData={filterData}
                    tableData={tableData}
                    setFilterData={setFilterData}
                    currentPageNo={currentPageNo}
                    handleChange1={handleChange1}
                    handleSorting={handleSorting}
                    sortConfig={sortConfig}
                />
            </main>
            {isLoading && <Loader />}
        </div>

    )
}

export default UserMasterNew;