import { createSlice } from "@reduxjs/toolkit";

export const commonData = createSlice({
    name: "commonData",
    initialState: {
        reginoalEsim: [],
        countryEsim: [],
    },
    reducers: {
        reginoalEsim: (state, action) => {
            state.reginoalEsim = action.payload;
        },
        countryEsim: (state, action) => {
            state.countryEsim = action.payload;
        },
    },
});

export const { reginoalEsim, countryEsim } = commonData.actions;
export default commonData.reducer;
