import { useEffect, useState } from 'react';
import "react-datepicker/dist/react-datepicker.css";
import { useDispatch } from 'react-redux';
import { AdminApiCall } from '../../../EnvConfig/env_config';
import { adminSessionExpire } from '../../../features/authSlice';
import { FileUploadChild, dateFormateWithTime, handleDateChange, handleDownloadExcel, number, setFocus } from '../../Common/Common';
import { AlertBox, ConfirmAlertBox, DateBox, InputBox, SelectInputBox } from '../../Common/Input';
import Loader from '../../Common/Loader';
import { NewTable } from '../../Common/NewTable';
import { FileUploader } from "react-drag-drop-files";


const PaymentDiscrepancyLogReport = ({ open, setOpen }) => {
    const [edit, setEdit] = useState(false)
    const [showform, setShowForm] = useState(false)
    const [isLoading, setIsLoading] = useState(false);
    const [tableData, settableData] = useState([]);
    const [groupNameDropdown, setGroupNameDropdown] = useState([]);
    const [currentPageNo, setCurrentPageNo] = useState(1);
    const [sortConfig, setSortConfig] = useState({ sortColumn: null, sortOrder: null });
    const [pageSize, setPageSize] = useState("10");
    const [fileName, setFileName] = useState()
    const [filterData, setFilterData] = useState({
        type: "S",
        SearchText: "",
        searchFieldValue: "",
        startValue: "",
    })
    const [formData, setFormData] = useState({
        promotionName: '',
        promotionType: '',
        minQuantity: '',
        discountType: 'Percentage',
        discountValue: '',
        startDate: '',
        endDate: '',
        image: '',
        planImageBase64: '',
        imageExt: '',
        imageName: '',
        isActive: true,
    });
    const handleClear = () => {
        setFormData({
            promotionName: '',
            promotionType: '',
            minQuantity: '',
            discountType: 'Percentage',
            discountValue: '',
            startDate: '',
            endDate: '',
            image: '',
            planImageBase64: '',
            imageExt: '',
            imageName: '',
            isActive: true,
        });
        setEdit(false)
        setFocus('txtPromotionName');
    }
    const dispatch = useDispatch()
    const getUserMasterData = async () => {
        let request = {
            AdvanceFilter: [{
                "SearchingValue": filterData.SearchFieldValue,
                "ComparisonType": filterData.StartValue,
                "SearchingText": filterData.SearchText,
            }],
            ...sortConfig,
            pageIndex: `${currentPageNo}`
        }
        try {
            setIsLoading(true)
            let res = await AdminApiCall('/api/Report/PaymentDiscrepancyLog', { ...request, pageSize: pageSize })
            if (res.Status === 'SUCCESS') {
                settableData(res);
            } else if (res.Status === 'EXPIRED') {
                dispatch(adminSessionExpire({
                    adminSessionExpires: true,
                    status: res.Status,
                    message: res.Message
                }))
            }
            else if (res.Status === 'ERROR') {
                settableData([])
            }
        }
        catch (error) {
            AlertBox('ERROR', 'Failed to show the data. Please try again.')
        }
        finally {
            setIsLoading(false)
        }
    }

    const handleSorting = (SortColumn) => {
        let SortOrder = 'ASC';

        if (sortConfig && sortConfig.SortColumn === SortColumn && sortConfig.SortOrder === 'ASC') {
            SortOrder = 'DESC';
        }
        setSortConfig({ SortColumn, SortOrder });
    };


    const handleDownLoadExcel = () => {
        let request = {
            AdvanceFilter: [{
                "SearchingValue": filterData?.SearchFieldValue,
                "ComparisonType": filterData?.StartValue,
                "SearchingText": filterData?.SearchText,
            }],
            Type: 'E',
            PageIndex: '1',
            ...sortConfig,
            pageIndex: `${currentPageNo}`,
            pageSize: pageSize
        }
        handleDownloadExcel('/api/Report/PaymentDiscrepancyLog', fileName, request)
    }

    const handleChange1 = (event, pagenumber) => {
        setCurrentPageNo(pagenumber)
    }


    useEffect(() => {
        document.title = 'Payment Discrepancy Log Report'
        setFileName(`Payment Discrepancy Log Report ${dateFormateWithTime(new Date())}`)
        setFocus("txtPromotionName")
        getUserMasterData()
    }, [currentPageNo, sortConfig, pageSize])


    return (
        <div style={{ marginTop: "64px" }}>
            <main id="main" className={`main ${open ? '' : 'margin_left'}`}  >
                <section className="p-2 mt-2">
                    <div className='headerbg p-3  d-flex justify-content-between'>
                        <h4 className='primary-text'>{'Payment Discrepancy Log Report'}</h4>
                    </div>
                </section>

                <NewTable
                    PageName={'PaymentDiscrepancyLog'}
                    handleFilter={() => {
                        getUserMasterData()
                        setFilterData({
                            ...filterData,
                        })
                    }}
                    filterData={filterData}
                    tableData={tableData}
                    setFilterData={setFilterData}
                    currentPageNo={currentPageNo}
                    handleChange1={handleChange1}
                    handleSorting={handleSorting}
                    sortConfig={sortConfig}
                    exportExcelData={true }
                    handleDownLoadExcel={handleDownLoadExcel}
                />
            </main>
            {isLoading && <Loader />}
        </div>

    )
}

export default PaymentDiscrepancyLogReport;






