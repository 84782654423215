import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { ApiCall } from '../../EnvConfig/env_config';
import { toast } from 'react-toastify';
import { setFocus } from '../Common/Common';

const ForgotPassword = () => {
    const [email, setEmail] = useState('')
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()

    const handleResetPassword = async (e) => {
        e.preventDefault()
        let req = {
            UserCode : email
        }
        try {
            setLoading(true)
            const response = await ApiCall(`/api/Web/ForgetPassword`, req);
           
            if (response.status === 'SUCCESS') {
                toast.success(response.message)
                setEmail('')
                setLoading(false)

            } else if (response.status === 'ERROR') {
                toast.error(response.message)
                setLoading(false)

            }
        } catch (error) {
            toast.error('There was a problem with the server, Please try again later');
            setLoading(false)

        }
    }
    useEffect(() => {
        setFocus('txtEmailID')
    },[])
    return (
        <>
            <div className="login_wrapper">
                <div className="container">
                    <div className="row justify-content-center align-items-center">
                        <div className="col-md-6 col-lg-5 col-xl-4">
                            <div className="text-center mb-3 mb-md-5">
                                <a className="navbar-brand fw-bold fs-1 primary-text" href="/">Simfly</a>
                            </div>
                      
                            <div className="card br-12">
                                <div className="card-body">
                                    <h5 className='text-center mb-3'>Reset Your Password Here</h5>
                                    <form>
                                        <div className="form-group mb-3">
                                            <input
                                                type="email"
                                                className="form-control"
                                                id='txtEmailID'
                                                name='email'
                                                value={email}
                                                placeholder="Enter email"
                                                onChange={(e) => setEmail(e.target.value)}
                                                onKeyDown={(e) => {
                                                    if (e.key === 'Enter') {
                                                        handleResetPassword(e)
                                                    };
                                                }}
                                            />
                                        </div>
                                        {
                                            loading ? <button className="mybtn primary-bg text-white w-100">
                                                loading...
                                            </button> : 
                                        
                                        <button onClick={(e) => handleResetPassword(e) } className="mybtn primary-bg text-white w-100">
                                            Reset Your Password
                                        </button>
                                        }
                                        <div className="mt-3 text-center">
                                            <Link className='small' to="/login">Login</Link>
                                        </div>
                                        <div className="mt-3 text-center">
                                            <span
                                                className='pointer'
                                                onClick={async () => {
                                                    await localStorage.setItem("signAsGuest", true)
                                                    navigate("/")
                                                }}
                                            ><i className="bi bi-person-badge me-2"></i>Signin as a guest</span>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ForgotPassword;