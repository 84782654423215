import { React, useEffect, useState } from 'react'
import { AdminApiCall } from '../../EnvConfig/env_config';

const Privacypolicy = () => {
    const [formdata, setFormdata] = useState({
        emailFrom: "",
        supportMail: ""

    })
    const GetGeneralSettingValues = async () => {
        try {
            let res = await AdminApiCall('/api/AutoComplete/GetGeneralSettingValue')
            if (res.status === 'SUCCESS') {
                setFormdata({ ...formdata, emailFrom: res.emailFrom, supportMail: res.supportMail })
            }
            else if (res.status === 'ERROR') {
                setFormdata({ ...formdata, emailFrom: '', supportmail: '' })
            }
        }
        catch (error) {
            console.log('Failed to show the data. Please try again.')
        }
    }

    useEffect(() => {
        GetGeneralSettingValues();
    }, [])
    return (
        <>
            <section>
                <div className="container">
                    <h1>Privacy & Cookie Policy</h1>
                    <br />
                    <h5>Introduction</h5>
                    <p>Simfly ("we," "our," or "us") is committed to protecting your privacy and ensuring the security of your personal information. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you use our eSIM services, website, and mobile application.</p>
                    <p>By using Simfly's services, you agree to the terms outlined in this Privacy Policy.</p>

                    <h5>Information We Collect</h5>
                    <p>We collect several types of information, including:</p>
                    <ol className='ps-3' type='1'>
                        <li>Personal Information: Name, email address, phone number, payment details, and identification documents (if required for verification).</li>
                        <li>Device Information: IP address, device model, operating system, and other related technical information for optimizing service delivery.</li>
                        <li>Usage Data: Information regarding how you use our services, including data usage, connection times, and network preferences.</li>
                        <li>Location Data: When applicable, we may collect information about your geographical location for providing regional-specific services and support.</li>
                    </ol>

                    <h5>How We Use Your Information</h5>
                    <p>We use the information we collect for the following purposes:</p>
                    <ul>
                        <li>To provide and maintain our eSIM services.</li>
                        <li>To process payments and transactions securely.</li>
                        <li>To improve, personalize, and develop our service offerings.</li>
                        <li>To communicate with you regarding your account, subscriptions, and customer support queries.</li>
                        <li>To comply with legal obligations or requests from regulatory authorities.</li>
                        <li>To analyze and improve the security and performance of our systems.</li>
                    </ul>

                    <h5>Sharing Your Information</h5>
                    <p>We may share your information with:</p>
                    <ul>
                        <li><b>Service Providers:</b> Third-party vendors who assist us in service operations, payment processing, data analysis, or customer support.</li>
                        <li><b>Business Transfers:</b> In case of a merger, acquisition, or asset sale, your information may be transferred.</li>
                        <li><b>Legal Compliance:</b> We may disclose your information to comply with legal obligations, court orders, or government regulations.</li>
                    </ul>
                    <p>We do not sell, trade, or rent your personal information to third parties for marketing purposes.</p>

                    <h5>Data Security</h5>
                    <p>We take the security of your personal information seriously and employ industry-standard encryption and secure data storage to protect it from unauthorized access, alteration, or disclosure. However, please note that no method of data transmission over the internet or electronic storage is 100% secure.</p>

                    <h5>Data Retention</h5>
                    <p>We retain your personal information for as long as your account is active or as necessary to provide you with our services, comply with legal obligations, resolve disputes, and enforce agreements.</p>

                    <h5>Your Data Protection Rights</h5>
                    <p>Depending on your location, you may have the following rights regarding your personal data:</p>
                    <ul>
                        <li>The right to access your personal data.</li>
                        <li>The right to request the correction or deletion of your personal data.</li>
                        <li>The right to object to or restrict the processing of your personal data.</li>
                        <li>The right to withdraw consent to data processing at any time.</li>
                        <li>The right to request a copy of your personal data in a machine-readable format.</li>
                    </ul>

                    <p>To exercise these rights, please contact us at <a href={`mail:${formdata.emailFrom ? formdata.emailFrom : ""}`} className="text-primary">{formdata.emailFrom ? formdata.emailFrom : ""}</a>.</p>

                    <h5>Cookies and Tracking Technologies</h5>
                    <p>Simfly uses cookies and similar technologies to enhance your browsing experience, track usage, and personalize content. You can choose to disable cookies through your browser settings, but this may affect your experience on our platform.</p>

                    <h5>Changes to this Privacy Policy</h5>
                    <p>We reserve the right to update or modify this Privacy Policy at any time. Any changes will be reflected on this page with an updated revision date. We encourage you to review this policy regularly for any updates.</p>

                    <h5>Contact Us</h5>
                    <p>If you have any questions about this Privacy Policy or how your data is handled, please contact us at:</p>
                    <p><b>Address : </b>Rooms 05-15, 13A/F
                        South Tower, World Finance Centre
                        Harbour City, 17 Canton Road <br></br>
                        Tsim Sha Tsui, Kowloon
                        Hong Kong</p>
                    {/*<p> <b>Contact number</b> : +85226662888</p>*/}
                    <p> <b>Email ID</b> : {formdata.emailFrom ? formdata.emailFrom : ""}</p>
                </div>
            </section>
        </>
    )
}

export default Privacypolicy