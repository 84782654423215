import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { AdminApiCall } from "../../../EnvConfig/env_config";
import { adminSessionExpire } from "../../../features/authSlice";
import { downloadExcel } from '../../Common/Common';
import { AlertBox } from "../../Common/Input";
import Loader from "../../Common/Loader";

const UploadPlanImage = () => {
    const [isLoading, setIsLoading] = useState(false);
    const dispatch = useDispatch();
    const [files, setFiles] = useState([]);
    const [errorRecords, setErrorRecords] = useState([]);
    const [successRecords, setSuccessRecords] = useState([]);
    const [showExcelStatus, setShowExcelStatus] = useState(false);
    const [uploadExcelStatus, setUploadExcelStatus] = useState({
        totalData: "",
        sucessData: "",
        errorData: ""
    })

    const handleFileUpload = (e) => {
        const uploadedFiles = Array.from(e.target.files);
        const fileDetails = uploadedFiles.map((file) => ({
            name: file.name,
            extension: file.name.split('.').pop(),
            file,
        }));

        setFiles((prevFiles) => {
            const existingFileNames = prevFiles.map((file) => file.name);
            const newFiles = fileDetails.filter(
                (file) => !existingFileNames.includes(file.name)
            );
            return [...prevFiles, ...newFiles];
        });
    };

    const convertToBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result.split(",")[1]);
            reader.onerror = (error) => reject(error);
        });
    };

    const handleDelete = (fileName) => {
        setFiles((prevFiles) => prevFiles.filter((file) => file.name !== fileName));
    };

    const handleSubmit = async () => {
        if (files.length === 0) {
            AlertBox('ERROR', "Please Select files")
            return
        }
        setIsLoading(true)
        const planImageDetails = await Promise.all(
            files.map(async ({ name, file, extension }) => ({

                ImageName: name.split('.').slice(0, -1).join('.'),
                ImageExt: extension,
                ImageBase64: await convertToBase64(file),
            }))
        );
        try {
            let res = await AdminApiCall('/api/import/UploadPlanImage', { planImageDetails })
            setFiles([])
            setIsLoading(false)
            if (res.status === 'EXPIRED') {
                dispatch(adminSessionExpire({
                    adminSessionExpires: true,
                    status: res.status,
                    message: res.Message
                }))
            }
            await setSuccessRecords(res.successRecords)
            await setErrorRecords(res.errorRecords)
            setUploadExcelStatus({
                ...uploadExcelStatus,
                sucessData: res.totalNumberMergeUploadRecords,
                totalData: res.totalNumberUploadRecords,
                errorData: res.totalNumberErrorUploadRecords,
            })
            setShowExcelStatus(true)  
        }
        catch (error) {
            AlertBox('ERROR', 'Failed to show the data. Please try again.')
        }
        finally {
            setIsLoading(false)
        }
    };


    const resetField = async () => {
        setFiles([])
        setShowExcelStatus(false)
    }

    const downloadStatusExcel = (data) => {
        downloadExcel(data, 'UploadPlanImageErrorData')
    }

    useEffect(() => {
        document.title = 'Upload Plan Image'
    }, [])

    return (
        <div style={{ marginTop: "64px" }}>
            <main id="main" className={`main`}  >
                <section className="p-2 mt-2">
                    <div className='headerbg p-3  d-flex justify-content-between'>
                        <h4 className='primary-text px-2'>{'Upload Plan Image'}</h4>
                    </div>

                    <div className='admin_classmain-card rounded-2 mt-2 headerbg' >
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="row align-items-center">
                                    <div className="col-md-6">
                                        <p className='fw-bold'>Note</p>
                                        <ul>
                                            <li style={{ color: "red", fontSize: "16px", fontWeight: "bold" }}>1. Image name should be same as plan name</li>
                                            <li>2. Please upload only .JPG, .JPEG, .PNG.</li>
                                            <li>3. You can upload multiple image.
                                            </li>
                                        </ul>
                                        <div className="lms-new-button ">
                                            <button className="admin_mybtn mybtn secondary-bg text-dark me-2" onClick={() => handleSubmit()}>
                                                <i className="bi bi-check-lg " /> Upload
                                            </button>

                                            <button className="admin_mybtn mybtn secondary-bg text-dark me-2" onClick={() => resetField()}>
                                                <i className="bi bi-arrow-clockwise" /> Reset
                                            </button>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className=" d-flex align-items-center justify-content-center">

                                            <div>
                                                <label htmlFor="upload_xlsx" className="admin_upload_xlsx">
                                                    <input
                                                        type="file"
                                                        id="upload_xlsx"
                                                        className="d-none"
                                                        accept="image/jpeg, image/jpg, image/png"
                                                        multiple
                                                        onChange={handleFileUpload}
                                                    />
                                                    <i className="fs-5 fa fa-upload" aria-hidden="true"></i>
                                                    <h6 className="m-0">Upload multiple image</h6>
                                                    <span>.JPG, .JPEG, .PNG</span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {
                        files?.length !== 0 &&
                        <div className='admin_classmain-card rounded-2 mt-2 headerbg' >
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="row align-items-center px-2">
                                        <table class="table table-bordered">
                                            <thead>
                                                <tr style={{ padding: "0px" }}>
                                                    <th scope="col">Sr No.</th>
                                                    <th scope="col">File Name</th>
                                                    <th scope="col">Extension</th>
                                                    <th scope="col">Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {files?.map((file, index) => (
                                                    <tr key={index} >
                                                        <td style={{ padding: "0px 5px" }}>{index + 1}</td>
                                                        <td style={{ padding: "0px 5px" }}>{file.name}</td>
                                                        <td style={{ padding: "0px 5px" }}>{file.extension}</td>
                                                        <td style={{ padding: "0px 5px" }}>

                                                            <button
                                                                className="admin_mybtn mybtn secondary-bg text-dark me-2"
                                                                onClick={() => handleDelete(file.name)}
                                                            >
                                                                <i className="fa fa-trash" />
                                                            </button>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>

                                    </div>
                                </div>
                            </div>
                        </div>
                    }

                    {
                        showExcelStatus &&

                        <div className='admin_classmain-card rounded-2 mt-2 headerbg' >
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="row align-items-center px-2">
                                        <div className='d-flex align-items-center justify-content-between py-2'>
                                            <div className='fw-bold'>Process Completed</div>
                                            <div className='d-flex align-items-center justify-content-center gap-2 '>
                                                <div><span
                                                    style={{
                                                        padding: "3px 10px",
                                                        borderRadius: "5px",
                                                        cursor: "pointer",
                                                        color: "#fff",
                                                        backgroundColor: "rgb(78, 45, 135)",
                                                        fontSize: "14px",
                                                        marginLeft: "5px"
                                                    }}
                                                >Total  : {uploadExcelStatus.totalData} </span> </div>
                                                <div><span
                                                    style={{
                                                        padding: "3px 10px",
                                                        borderRadius: "5px",
                                                        cursor: "pointer",
                                                        color: "#fff",
                                                        backgroundColor: "#4BB543",
                                                        fontSize: "14px",
                                                        marginLeft: "5px"
                                                    }}
                                                    onClick={() => {
                                                        if (successRecords?.length !== 0) {
                                                              downloadStatusExcel(successRecords)
                                                        }
                                                    }}
                                                >
                                                    {
                                                        successRecords?.length !== 0 && <i className="fa fa-download color-success me-2" />
                                                    }

                                                    Success : {uploadExcelStatus.sucessData} </span> </div>
                                                <div> <span
                                                    style={{
                                                        padding: "3px 10px",
                                                        borderRadius: "5px",
                                                        cursor: "pointer",
                                                        color: "#fff",
                                                        backgroundColor: "#FF3333",
                                                        fontSize: "14px",
                                                        marginLeft: "5px"
                                                    }}
                                                    onClick={() => {
                                                        if (errorRecords?.length !== 0) {
                                                             downloadStatusExcel(errorRecords)
                                                        } 
                                                    }}
                                                >
                                                    {
                                                        errorRecords?.length !== 0 &&
                                                        <i className="fa fa-download color-success me-2" />
                                                    }
                                                    Error : {uploadExcelStatus.errorData}</span></div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </section>
            </main>
            {isLoading && <Loader />}
        </div>

    )
}
export default UploadPlanImage;




