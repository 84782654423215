import { Link, useNavigate } from "react-router-dom"

const CheckAuthenticationForPayment = () => {
    const navigate = useNavigate()
    return (
        <section>
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-md-6 text-center">
                                <h4>You Have logged out!</h4>
                                <div className="d-flex gap-2 align-items-center mt-4">
                                    <span
                                className="mybtn secondary-bg text-dark w-100 pointer"
                                onClick={() => {
                                    localStorage.setItem("signAsGuest", true)
                                    navigate("/checkout")
                                }}
                                    >Sign as guest</span>
                            <p className='m-0'>or</p>
                            <Link to='/login' className="mybtn secondary-bg text-dark w-100">login Again</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
    )
}

export default CheckAuthenticationForPayment