import React, { useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { ApiCall } from '../../EnvConfig/env_config';
import Loader from '../Common/Loader';
import { toast } from 'react-toastify'
import { useEffect } from 'react';
import { useSelector } from 'react-redux';

const Addonplans = () => {
    const [addTab, setAddTab] = useState(1);
    const [countryEsim, setCountryEsim] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const simId = params.get('simId');
    const [simNumber, setSimnumber] = useState(simId !== null ? simId : "");
    const authEmail = localStorage.getItem('emailID')
    const { reginoalEsim } = useSelector((state) => state.commonData)
    const navigate = useNavigate();


   // let reginoalEsim = []
    const handleSimChange = (e) => {
        setSimnumber(e.target.value)
    }

    const handleClick = (e, country, simNumber) => {
        e.preventDefault()
        navigate(`/plans/${country}`);

        //if (simNumber === '') {
        //    toast.info('Please choose your sim');
        //} else {
        //    navigate(`/plans/${country}?simNumber=${simNumber}`);
        //}
    };

    const GetQRCodeDetails = async () => {
        let request = {
            Emailid: authEmail,
        };
        try {
            const response = await ApiCall(`/api/Web/AddOnCountryList`, request);
            if (response.status === 'SUCCESS') {
                setCountryEsim(response.countryDetails)
                setIsLoading(false)
            } else if (response.status === 'ERROR') {
                setCountryEsim([])
                toast.error(response.message);
                setIsLoading(false)
            }
        } catch (error) {
            toast.error('There was a problem with the server, Please try again later');
        } finally {
            setIsLoading(false)
        }
    };
    useEffect(() => {
        GetQRCodeDetails()
    }, [])

    if (isLoading) {
        return <Loader />
    }

    return (
        <>
            <section className="py-4 partner_hero">
                <div className="container">
                    <div className="head_wrapper position-relative">
                        <div className="row align-items-center justify-content-center">
                            <div className="col-lg-6 mb-4 mb-lg-0">
                                <img
                                    src="./assets/images/addonplans.webp"
                                    className="img-fluid"
                                    alt=""
                                />
                            </div>
                            <div className="col-lg-6 py-3 py-md-0">
                                <h1>Add On Plans</h1>
                                <p className="fs-6">
                                    Upgrade your eSIM with flexible add-on plans. Enjoy extra data for streaming, browsing, or traveling, with no contracts or hidden fees—just seamless connectivity when you need it most.
                                </p>
                                {/*<div className="w-100">*/}
                                {/*    <div className='location_search position d-flex align-items-center position-relative'>*/}
                                {/*        <p className='text-nowrap m-0 primary-bg text-white px-2 py-1 rounded'>Select Your eSim :</p>*/}
                                {/*        <select onChange={(e) => handleSimChange(e)} className='form-select border-0 py-1' name="" id="">*/}
                                {/*            {simId !== null ?*/}
                                {/*                <option value={`${simId}`}>{simId}</option>*/}
                                {/*                :*/}
                                {/*                <>*/}
                                {/*                    <option value={``}>--choose your sim--</option>*/}
                                {/*                    {*/}
                                {/*                        simDetails?.map((item, index) => <option key={index} value={`${item.iccid}`}>{item.iccid}</option>)*/}
                                {/*                    }*/}
                                {/*                </>*/}
                                {/*            }*/}
                                {/*        </select>*/}
                                {/*    </div>*/}
                                {/*</div>*/}
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container">
                    <div className="d-flex justify-content-center align-items-center gap-3">
                        <button className={`mybtn_border ${addTab === 1 ? 'active' : ''} py-3 px-4 text-dark mt-3 mt-md-0`} onClick={() => setAddTab(1)}>Country esim</button>
                        <button className={`mybtn_border ${addTab === 2 ? 'active' : ''} py-3 px-4 text-dark mt-3 mt-md-0`} onClick={() => setAddTab(2)}>Regional esim</button>
                    </div>

                    <div className="country_esim all_desti">
                        <div className="mt-5">
                            {addTab === 1 ?
                                <div className="row">
                                    {countryEsim?.map((country, index) => (
                                        <div className="col-md-4 col-lg-3 mb-3 mb-lg-4" key={index}>
                                            <Link
                                                onClick={(e) => handleClick(e, country?.country)}
                                                to={simNumber !== '' ? `/plans/${country?.country}` : '#'}
                                                target='_blank'
                                                className="sim_country d-flex align-items-center text-center justify-content-between light-bg br-12 py-2 ps-2 pe-3">
                                                <div className="country_image d-flex align-items-center gap-2 m-0 bg-transparent">
                                                    <img
                                                        style={{ height: "30px", width: "50px", objectFit: "cover" }}
                                                        src={country.countryFlagBase64}
                                                        alt={country.country}
                                                    />
                                                    <h6 className="m-0">{country?.country}</h6>
                                                </div>
                                                <i className="bi bi-chevron-right"></i>
                                            </Link>
                                        </div>
                                    ))}
                                </div>
                                :
                                <div className="row">
                                    {reginoalEsim?.map((country, index) => (
                                        <div className="col-md-4 col-lg-3 mb-3 mb-lg-4" key={index}>
                                            <Link
                                                onClick={(e) => handleClick(e, country?.regionName)}
                                                to={simNumber !== '' ? `/plans/${country?.regionName}` : '#'}
                                                className="sim_country d-flex align-items-center text-center justify-content-between light-bg br-12 py-2 ps-2 pe-3">
                                                <div className="country_image d-flex align-items-center gap-2 m-0 bg-transparent">
                                                    <h6 className="m-0">{country?.regionName}</h6>
                                                </div>
                                                <i className="bi bi-chevron-right"></i>
                                            </Link>
                                        </div>
                                    ))}
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Addonplans



