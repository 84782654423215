



const MiniLoader = ({ size = "50px", top = "10%" }) => (
    <div style={{
        position: "absolute", 
        top: top,         
        left: "50%",
        transform: "translate(-50%, -50%)",
    }}>
        <div style={{
            height: size,
            width: size,
            backgroundColor: "gray", 
            borderRadius: "50%",     
            animation: "spin 1s linear infinite" 
        }} className="mini_loader t"></div>
    </div>
);

export default MiniLoader;