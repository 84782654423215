import React, { useEffect, useState } from "react";
import LiveSearchBar from "../Common/LiveSearchBar";
import { ApiCall } from "../../EnvConfig/env_config";
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom'
import MiniLoader from "../Common/MiniLoader";

const ViewAllDestination = () => {
    const [country, setCountry] = useState([])
    const [countryLoading, setCountryloading] = useState(true)

    const getAllDestinationdata = async () => {
       
        try {
            const response = await ApiCall(`/api/Web/AllDestination`);
            if (response.status === "SUCCESS") {
                setCountry(response.countryDetails)
                setCountryloading(false)
            } else if (response.status === 'ERROR') {
                setCountry([])
                setCountryloading(false)
            }
        } catch (error) {
            toast.error('There was a problem with the server, Please try after sometimes.');
        }
    }
    useEffect(() => {
        getAllDestinationdata()
    },[])
  return (
    <>
      <section className="py-4 partner_hero">
        <div className="container">
          <div className="head_wrapper position-relative">
            <div className="row align-items-center justify-content-center">
              <div className="col-lg-6 mb-4 mb-lg-0">
                <img
                  src="./assets/images/alldestination.webp"
                  className="img-fluid"
                  alt=""
                />
              </div>
              <div className="col-lg-6 py-3 py-md-0">
                <h1> All destinations</h1>
                <p className="fs-5">
                    Discover the best data plans in over 150 countries and stay connected with reliable, secure internet access wherever your travels take you.
                </p>
                <div className="location_search position d-flex align-items-center position-relative">
                  <LiveSearchBar />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="all_desti">
              <div className="container">
                  {
                      !countryLoading ? 
                          <div className="row">
                              {country?.map((item, index) => {
                                  return (
                                      <div className="col-md-4 col-lg-3 mb-3 mb-lg-4" key={index}>
                                          <Link to={`/plans/${item.country}`} className="sim_country d-flex align-items-center text-center justify-content-between light-bg br-12 py-2 ps-2 pe-3">
                                              <div className="country_image d-flex align-items-center gap-2 m-0 bg-transparent">
                                                  <img style={{ height: "30px", width: "50px", objectFit: "cover" }} src={item.countryFlagBase64} alt={`${item.country}`} />
                                                  <h6 className="m-0">{item.country}</h6>
                                              </div>
                                              <i class="bi bi-chevron-right"></i>
                                          </Link>
                                      </div>
                                  )
                              })}
                          </div>
                          : 
                          <div style={{ minHeight: "100px" }} className="filter_box light-bg br-12 p-3">
                              <div style={{ position: "relative", marginTop: "30px" }} > <MiniLoader />  </div>
                          </div>
                  }
           
         </div>
      </section>
    </>
  );
};

export default ViewAllDestination;
