import { React, useEffect, useState } from 'react'
import { AdminApiCall } from '../../EnvConfig/env_config';

const RefundPolicy = () => {
    const [formdata, setFormdata] = useState({
        emailFrom: "",
        supportMail:""

    })
    const GetGeneralSettingValues = async () => {
        let request = {
            key: 'EmailFrom'
        }
        try {
            let res = await AdminApiCall('/api/AutoComplete/GetGeneralSettingValue', { ...request })
            if (res.status === 'SUCCESS') {
                setFormdata({ ...formdata, emailFrom: res.emailFrom, supportMail: res.supportMail })
            }
            else if (res.status === 'ERROR') {
                setFormdata({ ...formdata, emailFrom: '',supportmail:'' })
            }
        }
        catch (error) {
            console.log('Failed to show the data. Please try again.')
        }
    }

    useEffect(() => {
        GetGeneralSettingValues();
    }, [])
  return (
    <>
        <section>
            <div className="container">
                <h1>Refund Policy</h1>
                <br />

                <h5>Introduction</h5>
                <p>At Simfly, customer satisfaction is our priority. This Refund Policy outlines the terms and conditions under which refunds are granted for eSIM services purchased through our platform.</p>

                <p>By purchasing our services, you agree to this Refund Policy.</p>

                <h5>Eligibility for Refunds</h5>
                <p>Refunds may be considered under the following circumstances:</p>
                <ul>
                    <li><strong>Service Activation Issues</strong>: If your eSIM service fails to activate due to a technical issue on our end and we are unable to resolve the issue within a reasonable time frame.</li>
                    <li><strong>Non-Usage</strong>: If you have purchased an eSIM plan but have not used any data, you may be eligible for a refund, subject to the conditions below.</li>
                    <li><strong>Incorrect Plan Purchase</strong>: If you purchased the wrong plan by mistake and notify us before activation or usage.</li>
                    <li><strong>Overcharging</strong>: In the event of a billing error or overcharge, we will review the transaction and process a refund if appropriate.</li>
                </ul>

                <h5>Non-Refundable Cases</h5>
                <p>Refunds are not applicable in the following cases:</p>
                <ul>
                    <li><strong>Partially Used Plans</strong>: If any data has been used from the eSIM plan, a refund will not be provided.</li>
                    <li><strong>Change of Mind</strong>: Refunds will not be issued if you simply change your mind after purchasing an eSIM plan.</li>
                    <li><strong>Network Coverage Issues</strong>: We provide services based on the coverage information provided by our network partners. We are not responsible for issues related to local network coverage, and such cases are not eligible for refunds.</li>
                    <li><strong>Expiration of Plan</strong>: If the validity period of the purchased plan has expired without usage, refunds will not be issued.</li>
                </ul>

                <h5>Refund Process</h5>
                <p>If you believe you are eligible for a refund, please follow these steps:</p>
                <ul>
                      <li><strong>Submit a Refund Request</strong>: Contact our support team at <a href={`mail:${formdata.supportMail ? formdata.supportMail : ""}`}>{formdata.supportMail ? formdata.supportMail : ""}</a> with your order details, the reason for the refund, and any relevant screenshots or documentation.</li>
                    <li><strong>Review Process</strong>: Once we receive your request, we will review your claim within <strong>7 business days</strong>. We may ask for additional information or documents to validate the request.</li>
                    <li><strong>Refund Approval</strong>: If your refund is approved, the refund will be processed back to the original payment method. Please allow <strong>5-10 business days</strong> for the funds to reflect in your account, depending on your payment provider.</li>
                </ul>

                <h5>Changes to This Policy</h5>
                <p>We reserve the right to modify this Refund Policy at any time. Any updates will be posted on this page with an updated revision date. We encourage you to review this policy periodically for any changes.</p>

                <h5>Contact Us</h5>
                  <p>If you have any questions about our Refund Policy or need assistance with a refund request, please reach out to us:</p>
                  <p><b>Address : </b>Rooms 05-15, 13A/F
                      South Tower, World Finance Centre
                      Harbour City, 17 Canton Road <br></br>
                      Tsim Sha Tsui, Kowloon
                      Hong Kong</p>
                  {/*<p> <b>Contact number</b> : +85226662888</p>*/}
                  <p> <b>Email ID</b> : {formdata.emailFrom ? formdata.emailFrom : ""}</p>
            </div>
        </section>
    </>
  )
}

export default RefundPolicy