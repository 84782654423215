
import { createSlice } from "@reduxjs/toolkit";

const CART_STORAGE_KEY = "cartProduct";

const loadCartFromStorage = () => {
    const cart = localStorage.getItem(CART_STORAGE_KEY);
    return cart ? JSON.parse(cart) : [];
};

const saveCartToStorage = (cart) => {
    localStorage.setItem(CART_STORAGE_KEY, JSON.stringify(cart));
};

const CartItemData = JSON.parse(localStorage.getItem('cartProduct')) || [];

export const addToCartSlice = createSlice({
    name: "cart",
    initialState: {
        cart: loadCartFromStorage(),
        totalQuantity: 0,
        totalPrice: 0,
        cartCount: CartItemData?.length,
        cartModal : false,
    },
    reducers: {
        addToCart: (state, action) => {
            const testt = action.payload;
            state.cart = testt;
        },
        setCartCount: (state, action) => {
            state.cartCount = action.payload;
        },
        setCartModal: (state, action) => {
            state.cartModal = action.payload;
        },
        removeFromCart: (state, action) => {
            state.cart = state.cart.filter(
                (item) => item.planID !== action.payload
            );
            saveCartToStorage(state.cart);
        },
        getCartTotal: (state) => {
            let { totalQuantity, totalPrice } = state.cart.reduce(
                (cartTotal, cartItem) => {
                    const { sellingPrice, quantity } = cartItem;
                    const itemTotal = parseFloat(sellingPrice) * quantity;
                    const total = itemTotal.toFixed(2)
                    cartTotal.totalPrice += Number(total);
                    cartTotal.totalQuantity += parseInt(quantity);
                    return cartTotal;
                },
                {
                    totalPrice: 0,
                    totalQuantity: 0,
                }
            );

            // Ensure totalPrice has two decimal places
            state.totalPrice = totalPrice;
            state.totalQuantity = totalQuantity;
        },

        increaseItemQuantity: (state, action) => {
            state.cart = state.cart.map((item) => {
                if (item.planID === action.payload) {
                    return { ...item, quantity: item.quantity + 1 }; 
                }
                return item; 
            });
        },
        decreaseItemQuantity: (state, action) => {
            state.cart = state.cart.map((item) => {
                if (item.planID === action.payload) {
                    return { ...item, quantity: Math.max(item.quantity - 1, 0) }; 
                }
                return item;
            });
        },
        resetCart: (state) => {
            state.cart = [];
        },
    },
});

export const {
    addToCart,
    removeFromCart,
    setCartModal,
    setCartCount,
    increaseItemQuantity,
    decreaseItemQuantity,
    getCartTotal,
    resetCart } = addToCartSlice.actions;

export default addToCartSlice.reducer;
